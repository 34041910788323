import { styled } from '@mui/system';
import { Grid } from '@mui/material';

export const GridBackDropFilter = styled(Grid)({
  backgroundColor: '#14151471',
  WebkitBackdropFilter: 'sepia(100%)',
  backdropFilter: 'sepia(100%)',
  padding: '0.5rem !important',
  paddingLeft: '2rem !important',
  paddingRight: '2rem !important',
  marginBottom: '1.5rem',
  border: '2px #14151471 solid',
});

export const GridTransparentBackGround = styled(Grid)({
  backgroundColor: 'transparent !important',
  width: '28vw',
});
