import { useRef } from 'react';

const useDebounce = (func, delay) => {
  // useRef is used to store the timeout ID across renders, ensuring the same timeout ID is accessible
  // during successive renders for clearing and resetting the timeout.
  const timeoutRef = useRef(null);

  return (...args) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    // This delay resets if the returned function is invoked again within the delay period.
    timeoutRef.current = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export default useDebounce;
