import { Box, Button, Modal, Typography, Grid, Divider, IconButton } from '@mui/material';
import { useState, Fragment } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  modalStyle,
  StyledButton,
  StyledLink,
  StyledBackground,
  StyledBox,
  StyledAboutUsButton,
} from '../css/commonStyles';
import { ThemeProvider } from '@mui/material/styles';

import { getStatusStyle } from '../utils/HomePageUtils';
import { useAuth } from '../contexts/authContext';
import { BootstrapTooltip } from '../css/muiStyles';
import { globalTheme } from '../theme';

const Home = () => {
  // START of Model =============================
  const { logout, firstname } = useAuth();
  const [backendInfo, setBackendInfo] = useState({
    numQueuedTasks: 0,
    backendStatus: 'idle',
  });
  const [open, setOpen] = useState(false); // State for modal
  // END of Model ===============================

  // START of Controller =============================
  const statusStyle = getStatusStyle(backendInfo);

  // to-do: uncomment once this api is available in backend
  // useEffect(() => {
  //   callAPI('currentRunning', 'GET', {}, 'TEXT')
  //     .then((data) => {
  //       const [status, queueSize] = data.split(', ');
  //       if (status === 'None') {
  //         setBackendInfo({ numQueuedTasks: 0, backendStatus: 'idle' });
  //       } else if (status === 'Running') {
  //         setBackendInfo({ numQueuedTasks: parseInt(queueSize), backendStatus: 'running' });
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //       setBackendInfo({ numQueuedTasks: -1, backendStatus: 'error' });
  //     });
  // }, []);
  // END of Controller =============================

  // START of View =============================
  return (
    <Fragment>
      <Box sx={StyledBackground}>
        <Box sx={StyledBox}>
          <ThemeProvider theme={globalTheme}>
            <Typography variant='h3' component='h3' color='white' fontWeight='bold' gutterBottom>
              SAFETY AWARE <br /> DRONE ECOSYSTEM
            </Typography>
          </ThemeProvider>
          <Box
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
              borderBottom: '2px white solid',
            }}
          >
            <ThemeProvider theme={globalTheme}>
              <Typography variant='h5' color='white'>
                Simulation Configuration Tool for SADE
              </Typography>
            </ThemeProvider>
          </Box>
          <Grid container spacing={2} sx={{ pb: 18 }}>
            <Grid item xs={12}>
              <StyledLink to='/simulation'>
                <StyledButton variant='contained'>CREATE NEW SIM CONFIg</StyledButton>
              </StyledLink>
            </Grid>
            <Grid item xs={12}>
              <StyledLink to='/simulation/configurations'>
                <StyledButton variant='contained'>REUSE PREVIOUS SIM CONFIG</StyledButton>
              </StyledLink>
            </Grid>
          </Grid>
        </Box>
        <Button sx={StyledAboutUsButton} onClick={() => setOpen(true)}>
          About Us
        </Button>

        <Box
          style={{
            position: 'absolute',
            top: '2rem',
            left: '60vw',
            width: '30vw',
          }}
        >
          <Grid container justifyContent='space-between'>
            <Grid item xs={3} md={4}>
              <Box border={1} borderColor={statusStyle.color} p={2} borderRadius={2}>
                <Typography>
                  Backend Status: <span style={statusStyle}>{backendInfo.backendStatus}</span>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1} sm={2} md={4}></Grid>
            <Grid item xs={4} display='flex' justifyContent='center' alignItems='center'>
              <Typography variant='h6' component='span' style={{ marginRight: 8 }}>
                Hi,
              </Typography>
              <Typography variant='h5' component='span' style={{ fontWeight: 'bold' }}>
                {firstname.toUpperCase()}
              </Typography>
              <BootstrapTooltip title='Logout' placement='bottom'>
                <IconButton onClick={() => logout()}>
                  <LogoutIcon fontSize='large' />
                </IconButton>
              </BootstrapTooltip>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/*Remove this code when implementing the header About us. Widen the component. Add a close button.*/}
      {/* Button to test Modal*/}
      {/* <Button onClick={() => setOpen(true)} style={{ marginTop: 20, marginBottom: 20 }}>About Us (Move this to Nav Bar later)</Button> */}

      {/* Modal Component */}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={modalStyle}>
          {/* Close Button */}
          <Button
            onClick={() => setOpen(false)}
            style={{ position: 'absolute', top: 16, right: 16 }}
          >
            Close
          </Button>

          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {/* Title Content */}
          </Typography>

          <Box id='modal-modal-description' sx={{ mt: 2 }}>
            <Typography variant='body1' gutterBottom sx={{ pt: 1 }}>
              <strong>About Drone World</strong>
            </Typography>
            <Typography variant='body1' gutterBottom sx={{ pt: 1 }}>
              Drone World is revolutionizing sUAS (small Uncrewed Aerial Systems) testing. In the
              dynamic world of sUAS, safety and reliability are paramount. Traditional field testing
              across diverse environments is costly and challenging.
            </Typography>
            <Typography variant='body1' gutterBottom sx={{ pt: 1 }}>
              Drone World offers an innovative sUAV simulation ecosystem that generates
              high-fidelity, realistic environments mimicking real-world complexities like adverse
              weather and wireless interference. Our automated solution allows developers to specify
              constraints and generate tailored test environments.
            </Typography>
            <Typography variant='body1' gutterBottom sx={{ pt: 1 }}>
              The program monitors sUAV activities against predefined safety parameters and
              generates detailed acceptance test reports. This approach provides actionable insights
              for effective debugging and analysis, enhancing the safety, reliability, and
              efficiency of sUAS applications.
            </Typography>
          </Box>
        </Box>
      </Modal>

      {/* </Box> */}
    </Fragment>
  );
};

// END of View =============================

export default Home;
