import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/HomePage';
import Simulation from './pages/SimulationPage';
import FuzzyDashboard from './components/FuzzyDashboard';
import ReportDashboard from './components/ReportDashboard';
import AboutUs from './components/AboutUs';
import SimulationConfigs from './components/Configuration/SimulationConfigs';
import SignIn from './pages/SignInPage';
import SignUp from './pages/SignUpPage';
import { AuthProvider } from './contexts/authContext';
import ProtectedRoute from './components/ProtectedRoute';
import './css/styles.css';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path='/signin' element={<SignIn />} />
          <Route path='/signup' element={<SignUp />} />
          <Route
            path='/'
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path='/simulation'
            element={
              <ProtectedRoute>
                <Simulation />
              </ProtectedRoute>
            }
          />
          <Route
            path='/simulation/configurations'
            element={
              <ProtectedRoute>
                <SimulationConfigs />
              </ProtectedRoute>
            }
          />
          <Route
            path='/dashboard'
            element={
              <ProtectedRoute>
                <FuzzyDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path='/report-dashboard'
            element={
              <ProtectedRoute>
                <ReportDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path='/about-us'
            element={
              <ProtectedRoute>
                <AboutUs />
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
