import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { useMainJson } from '../contexts/MainJsonContext';
import { callAPI } from '../utils/ApiUtils';
import { SimulationConfigurationModel } from '../model/SimulationConfigurationModel';
import { EnvironmentModel } from '../model/EnvironmentModel';
import { CancelButton, SaveButton, StyledTextField } from '../css/muiStyles';
import { steps } from '../constants/simConfig';
import { useAuth } from '../contexts/authContext';
import useDebounce from './custom-hooks/useDebounce';
import { isTokenExpired } from '../utils/authUtils';

export default function SaveConfigModel({ isModalOpen, setIsModalOpen, activeStep }) {
  // START of DOM model ===================
  const navigate = useNavigate();
  const location = useLocation();
  const { mainJson, setMainJson, envJson, setEnvJson, getJSONStringForAPI } = useMainJson();
  const { authToken, logout } = useAuth();
  const [nameError, setNameError] = useState('');
  const [saveStatus, setSaveStatus] = useState('Save');
  const [isSaving, setIsSaving] = useState(false);
  const [saveConfig, setSaveConfig] = useState({
    name: '',
    description: '',
  });

  // END of DOM Model================

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleConfigChange = (e) => {
    const { name, value } = e.target;
    setSaveConfig((prev) => ({ ...prev, [name]: value }));

    if (name === 'name' && value) {
      checkConfigNameDebounced(value);
    }
  };

  const checkConfigName = (configName) => {
    if (isTokenExpired(authToken)) {
      logout();
      const serializedData = mainJson.toJSONString();
      localStorage.setItem('mainJson', JSON.stringify(serializedData));
      navigate('/signin', { state: { from: location } });
      return;
    }

    callAPI('api/sade_task/check_config_name', 'GET', { name: configName }, 'JSON', authToken)
      .then((data) => {
        if (data.isExists) {
          setNameError('Configuration name already exists!');
        } else {
          setNameError('');
        }
      })
      .catch((error) => {
        console.error('Error fetching config names:', error);
      });
  };

  // delay the API call by 500 milliseconds after the user stops typing.
  const checkConfigNameDebounced = useDebounce(checkConfigName, 500);

  const handleSave = () => {
    if (isTokenExpired(authToken)) {
      logout();
      const serializedData = mainJson.toJSONString();
      localStorage.setItem('mainJson', JSON.stringify(serializedData));
      navigate('/signin', { state: { from: location } });
      return;
    }

    if (nameError || !saveConfig.name) {
      return;
    }

    setIsSaving(true);
    invokePostAPI()
      .then(() => {
        setSaveStatus('Saved Successfully');
        setTimeout(() => {
          handleClose();
          localStorage.removeItem('mainJson');
          navigate('/');
        }, 800);
      })
      .catch((error) => {
        console.error('Error saving data:', error);
      });
  };

  const invokePostAPI = () => {
    envJson.name = saveConfig.name;
    envJson.description = saveConfig.description;
    setEnvJson(EnvironmentModel.getReactStateBasedUpdate(envJson));

    if (activeStep === steps.length - 1) {
      let mainJSONStringed = mainJson.toJSONString();
      console.log('mainJson-----', mainJSONStringed);
      return callAPI('api/sade_task', 'POST', mainJSONStringed, 'JSON', authToken)
        .then((data) => {
          console.log('Data saved successfully:', data);
        })
        .catch((error) => {
          throw error;
        });
    } else {
      return Promise.reject('Incomplete steps');
    }
  };

  return (
    <Dialog open={isModalOpen} onClose={isSaving ? null : handleClose}>
      <DialogTitle
        sx={{
          bgcolor: '#d88100',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant='h5' component='span' sx={{ fontWeight: 'bold' }}>
          SAVE CONFIGURATION
        </Typography>
        <IconButton onClick={handleClose} sx={{ color: 'white' }}>
          <CloseIcon fontSize='large' />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ bgcolor: 'black' }}>
        <Grid container spacing={2} direction='column' sx={{ marginTop: 1 }}>
          <Grid container item alignItems='center' direction='row'>
            <Grid item xs={4}>
              <InputLabel
                id='config-name-label'
                sx={{ marginRight: 2, flexShrink: 0, color: 'white', width: '200px' }}
              >
                NAME
              </InputLabel>
            </Grid>
            <Grid item xs={7}>
              <StyledTextField
                id='config-name'
                variant='outlined'
                onChange={(e) => handleConfigChange(e)}
                name='name'
                value={saveConfig.name}
                error={!!nameError}
                helperText={nameError}
                FormHelperTextProps={{
                  component: 'div',
                  style: { color: 'white' },
                }}
                InputProps={{
                  endAdornment: nameError && (
                    <IconButton>
                      <WarningAmberIcon color='error' />
                    </IconButton>
                  ),
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container item alignItems='center' direction='row'>
            <Grid item xs={4}>
              <InputLabel
                id='config-description-label'
                sx={{ marginRight: 2, flexShrink: 0, color: 'white', width: '200px' }}
              >
                DESCRIPTION
              </InputLabel>
            </Grid>
            <Grid item xs={7}>
              <StyledTextField
                id='config-description'
                variant='outlined'
                onChange={(e) => handleConfigChange(e)}
                name='description'
                value={saveConfig.description}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ bgcolor: 'black', p: 4 }}>
        {!isSaving && (
          <CancelButton variant='contained' onClick={handleClose} disabled={isSaving}>
            Cancel
          </CancelButton>
        )}

        <SaveButton variant='contained' onClick={handleSave} disabled={isSaving}>
          {saveStatus}
        </SaveButton>
      </DialogActions>
    </Dialog>
  );
}

SaveConfigModel.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired,
};
