import { EnvironmentModel } from '../../model/EnvironmentModel';
import { SadeModel } from '../../model/SadeModel';
import { WindModel } from '../../model/WindModel';
import { updateRectangle } from '../mapUtils';
import { Point } from '../../model/PointModel';

export function mapEnvironmentData(data) {
  const environment = new EnvironmentModel();

  environment.UseGeo = data.use_geo || false;
  environment.TimeOfDay = data.time || null;
  environment.time = new Date(data.date_created);

  // Mapping `origin` details
  if (data.origin) {
    environment.setOriginLatitude(data.origin.latitude);
    environment.setOriginLongitude(data.origin.longitude);
    environment.setOriginHeight(data.origin.height);
    environment.setOriginRadius(data.origin.radius);
    environment.setOriginName(data.origin.name);
  }

  // Mapping `wind` array
  if (data.wind && Array.isArray(data.wind)) {
    data.wind.forEach((w) => {
      let newWindBlock = new WindModel();
      newWindBlock.windDirection = w.wind_direction;
      newWindBlock.windType = w.wind_type;
      newWindBlock.windVelocity = w.wind_velocity;
      newWindBlock.fluctuationPercentage = w.fluctuation_percentage;
      environment.addNewWind(newWindBlock);
    });
  }

  // Mapping `sades` array
  if (data.sades && Array.isArray(data.sades)) {
    data.sades.forEach((s) => {
      let newSadeBlock = new SadeModel();
      newSadeBlock.name = s.sade_name;
      newSadeBlock.vertices = s.vertices.map(
        (vertex) => new Point(vertex.latitude, vertex.longitude),
      );
      newSadeBlock.length = s.length;
      newSadeBlock.width = s.width;
      newSadeBlock.height = s.height;
      newSadeBlock.centerLat = s.center_latitude;
      newSadeBlock.centerLong = s.center_longitude;
      newSadeBlock.rectangle = updateRectangle(
        s.center_longitude,
        s.center_latitude,
        s.length,
        s.width,
      );
      environment.addNewSade(newSadeBlock);
    });
  }

  return environment;
}
