export const WindDirection = [
  { value: 'N', id: 5 },
  { value: 'S', id: 6 },
  { value: 'E', id: 7 },
  { value: 'W', id: 8 },
  { value: 'NE', id: 1 },
  { value: 'SE', id: 2 },
  { value: 'SW', id: 3 },
  { value: 'NW', id: 4 },
];

export const WindType = [
  { value: 'Constant Wind', id: 1 },
  { value: 'Turbulent Wind', id: 2 },
];

// enum for simulation origin types
export const originTypes = {
  SpecifyRegion: 'Specify Region',
  MichiganLakeBeach: 'Michigan Lake Beach',
  ChicagoOhareAirport: 'Chicago O’Hare Airport',
  ChainLakeInIndiana: 'Chain Lake in Indiana',
  PeppermintFieldInIndiana: 'Peppermint Field in Indiana',
  HutchinsonFieldsInChicago: 'Hutchinson Fields in Chicago',
  TheFlyingFieldsAtSLU: 'The flying field(s) at SLU',
};

export const SIMULATION_ORIGINS = [
  { value: originTypes.SpecifyRegion, id: 1, latitude: 0, longitude: 0, height: 0 },
  { value: originTypes.MichiganLakeBeach, id: 2, latitude: 42.211223, longitude: -86.390394, height: 170 },
  { value: originTypes.ChicagoOhareAirport, id: 3, latitude: 41.980381, longitude: -87.934524, height: 200 },
  { value: originTypes.ChainLakeInIndiana, id: 4, latitude: 41.862301877313755, longitude: -85.90885956269466, height: 240.933 },
  { value: originTypes.PeppermintFieldInIndiana, id: 5, latitude: 41.60666312655653, longitude: -86.35548659327364, height: 193.973 },
  { value: originTypes.HutchinsonFieldsInChicago, id: 6, latitude: 41.87151449334061, longitude: -87.61886541338839, height: 145.735 },
  { value: originTypes.TheFlyingFieldsAtSLU, id: 7, latitude: 38.62575043383224, longitude: -90.23845989098999, height: 125.344 },
];

export const origin = {
  DEFAULT_RADIUS: 0.3,
};
