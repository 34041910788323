import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { isTokenExpired } from '../utils/authUtils';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));
  const [firstname, setFirstname] = useState(localStorage.getItem('firstname'));
  const [email, setEmail] = useState(localStorage.getItem('email'));

  const saveLoginData = (data) => {
    localStorage.setItem('authToken', data.access_token);
    localStorage.setItem('firstname', data.first_name);
    localStorage.setItem('email', data.email);
    setAuthToken(data.access_token);
    setFirstname(data.first_name);
    setEmail(data.email);
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('firstname');
    localStorage.removeItem('email');
    localStorage.removeItem('mainJson');
    setAuthToken(null);
    setFirstname(null);
    setEmail(null);
  };

  return (
    <AuthContext.Provider value={{ authToken, firstname, email, saveLoginData, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
