export const tabEnums = {
  ENV_REGION: 'env-region',
  ENV_WIND: 'env-wind',
  ENV_SADEZONE: 'env-sadezone',
  DRONES: 'drones',
};

export const configurationTabNames = [
  [tabEnums.ENV_REGION, tabEnums.ENV_WIND, tabEnums.ENV_SADEZONE],
  tabEnums.DRONES,
];

export const steps = [
  'Environment Configuration',
  'sUAS Configuration',
  //'Test Configuration'
];
