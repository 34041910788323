import { v4 as uuidv4 } from 'uuid';
import { Point } from './PointModel';
import { findRectangleCorners } from '../utils/mapUtils';

export class SadeModel {
  constructor() {
    this._id = uuidv4();
    this._name = `Sade-${this._id.substring(0, 4)}`;
    this._centerLat = null;
    this._centerLong = null;
    this._height = 100;
    this._length = null;
    this._width = null;
    this._rectangle = null;
    this._vertices = [];
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get centerLat() {
    return this._centerLat;
  }

  get centerLong() {
    return this._centerLong;
  }

  get height() {
    return this._height;
  }

  get length() {
    return this._length;
  }

  get width() {
    return this._width;
  }

  get rectangle() {
    return this._rectangle;
  }

  get vertices() {
    return this._vertices;
  }

  set id(value) {
    this._id = value;
  }

  set name(value) {
    this._name = value;
  }

  set centerLat(value) {
    this._centerLat = value;
  }

  set centerLong(value) {
    this._centerLong = value;
  }

  set height(value) {
    this._height = value;
  }

  set length(value) {
    this._length = value;
  }

  set width(value) {
    this._width = value;
  }

  set rectangle(value) {
    this._rectangle = value;
  }

  set vertices(value) {
    this._vertices = value;
  }

  updateVertices() {
    if (
      this._centerLat !== null &&
      this._centerLong !== null &&
      this._length !== null &&
      this._width !== null
    ) {
      const corners = findRectangleCorners(
        this._centerLong,
        this._centerLat,
        this._length,
        this._width,
      );

      this._vertices = [
        new Point(corners.lat1, corners.long1), // Top left
        new Point(corners.lat2, corners.long2), // Top right
        new Point(corners.lat3, corners.long3), // Bottom right
        new Point(corners.lat4, corners.long4), // Bottom left
      ];
    }
  }

  static getReactStateBasedUpdate(instance) {
    let model = new SadeModel();
    model.id = instance.id;
    model.name = instance.name;
    model.centerLat = instance.centerLat;
    model.centerLong = instance.centerLong;
    model.height = instance.height;
    model.length = instance.length;
    model.width = instance.width;
    model.rectangle = instance.rectangle;
    return model;
  }

  toJSONString() {
    return {
      sade_id: this._id,
      sade_name: this._name,
      center_latitude: this._centerLat,
      center_longitude: this._centerLong,
      height: this._height,
      length: this._length,
      width: this._width,
      vertices: this._vertices.map((vertex) => vertex.toJSONString()),
    };
  }
}
