import { DroneModel } from '../../model/DroneModel';

export function mapDroneData(dronesData) {
  return dronesData.map((droneData) => mapEachDrone(droneData));
}

function mapEachDrone(droneData) {
  const droneModel = new DroneModel();

  droneModel.name = droneData.name;
  droneModel.flightController = droneData.flight_controller;
  droneModel.droneType = droneData.drone_type;
  droneModel.droneModel = droneData.drone_model;
  droneModel.vehicleType = droneData.vehicle_type;
  droneModel.defaultVehicleState = droneData.default_vehicle_state;
  droneModel.enableCollisionPassthrogh = droneData.enable_collision_passthrough;
  droneModel.enableCollisions = droneData.enable_collisions;
  droneModel.allowAPIAlways = droneData.allow_api_always;
  droneModel.enableTrace = droneData.enable_trace;
  droneModel.color = droneData.color;
  droneModel.X = droneData.x;
  droneModel.Y = droneData.y;
  droneModel.Z = droneData.z;
  droneModel.Pitch = droneData.pitch;
  droneModel.Roll = droneData.roll;
  droneModel.Yaw = droneData.yaw;
  droneModel.Sensors = droneData.sensors;

  return droneModel;
}
