export const isTokenExpired = (token) => {
  try {
    // Decode JWT payload
    const decoded = JSON.parse(atob(token.split('.')[1]));
    // Check if token expiry time is in the past
    return decoded.exp * 1000 < Date.now();
  } catch {
    // Assume the token is invalid/expired if there's any error decoding it
    return true;
  }
};
