import { useEffect } from 'react';
import { Grid, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DeleteOutline } from '@mui/icons-material';
import PropTypes from 'prop-types';

import { WindDirection, WindType } from '../../constants/env';
import { renderSelectField, renderTextField } from '../../utils/SimulationPageUtils';
import { WindModel } from '../../model/WindModel';
import { EnvironmentModel } from '../../model/EnvironmentModel';
import { useMainJson } from '../../contexts/MainJsonContext';
import { configurationTabNames } from '../../constants/simConfig';
import { GridBackDropFilter, GridTransparentBackGround } from '../../css/windStyles';

const WindSettings = ({ envConf, setEnvConf, parentTabIndex, tabIndex }) => {
  const { activeScreen, setActiveScreen } = useMainJson();
  useEffect(() => {
    setActiveScreen(configurationTabNames[parentTabIndex][tabIndex]);
  });
  const addNewWindBlock = () => {
    let newWindBlock = new WindModel();
    envConf.addNewWind(newWindBlock);
    setEnvConf(EnvironmentModel.getReactStateBasedUpdate(envConf));
  };

  const setWindBlockData = (index, updatedData) => {
    let wind = envConf.getWindBasedOnIndex(index);
    wind.windType = updatedData.windType ? updatedData.windType : wind.windType;
    wind.windDirection = updatedData.windDirection ? updatedData.windDirection : wind.windDirection;
    wind.windVelocity = updatedData.windVelocity
      ? parseInt(updatedData.windVelocity)
      : wind.windVelocity;
    wind.fluctuationPercentage = updatedData.fluctuationPercentage
      ? parseFloat(updatedData.fluctuationPercentage)
      : wind.fluctuationPercentage;
    envConf.updateWindBasedOnIndex(index, wind);
    setEnvConf(EnvironmentModel.getReactStateBasedUpdate(envConf));
  };

  const performWindDelete = (index) => {
    envConf.deleteWindBasedOnIndex(index);
    setEnvConf(EnvironmentModel.getReactStateBasedUpdate(envConf));
  };

  return (
    <GridTransparentBackGround container>
      {envConf.Wind.map((windBlock, index) => (
        <GridBackDropFilter item container rowSpacing={1} key={index} xs={12}>
          {renderSelectField(
            'Wind Type',
            windBlock.windType,
            (e) => setWindBlockData(index, { windType: e.target.value }),
            WindType,
          )}
          {renderSelectField(
            'Wind Direction',
            windBlock.windDirection,
            (e) => setWindBlockData(index, { windDirection: e.target.value }),
            WindDirection,
          )}
          {renderTextField(
            'Wind Velocity (m/s)',
            windBlock.windVelocity,
            (e) => setWindBlockData(index, { windVelocity: e.target.value }),
            { min: 0 },
          )}

          {windBlock.windType === 'Turbulent Wind' &&
            renderTextField(
              'Fluctuation %',
              windBlock.fluctuationPercentage,
              (e) => setWindBlockData(index, { fluctuationPercentage: e.target.value }),
              { min: 0, max: 100, step: 0.1 },
            )}

          <Grid item xs={12}>
            <IconButton onClick={() => performWindDelete(index)}>
              <DeleteOutline color='error' />
            </IconButton>
          </Grid>
        </GridBackDropFilter>
      ))}

      <GridBackDropFilter item xs={12} sx={{ border: '1px #F5F5DC solid', textAlign: 'center' }}>
        <IconButton
          onClick={addNewWindBlock}
          color='warning'
          sx={{
            fontSize: '1.10rem', // Reduces font size
            '& .MuiSvgIcon-root': {
              fontSize: '2rem', // Reduces icon size
            },
            padding: '0px !important', // Reduces padding around the button
          }}
        >
          <AddIcon /> Add Wind Source
        </IconButton>
      </GridBackDropFilter>
    </GridTransparentBackGround>
  );
};

WindSettings.propTypes = {
  envConf: PropTypes.object.isRequired,
  setEnvConf: PropTypes.func.isRequired,
  parentTabIndex: PropTypes.number.isRequired,
  tabIndex: PropTypes.number.isRequired,
};

export default WindSettings;
