import OutlinedInput from '@mui/material/OutlinedInput';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { TextField } from '@mui/material';
import { StyledSelect } from '../../css/SimulationPageStyles';
import { BootstrapTooltip } from '../../css/muiStyles';
import {
  SIMULATION_ORIGINS,
  originTypes,
  origin,
} from '../../constants/env';
import { imageUrls } from '../../utils/const';
import { EnvironmentModel } from '../../model/EnvironmentModel';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import TimeGridComponent from './TimeGridComponent';
import { useMainJson } from '../../contexts/MainJsonContext';
import { configurationTabNames } from '../../constants/simConfig';
import { findCurrentLocation } from '../../utils/regionUtils';
import { useAuth } from '../../contexts/authContext';

const EnvironmentRegionSetting = ({ envConf, setEnvConf, parentTabIndex, tabIndex, configId }) => {
  const { activeScreen, setActiveScreen } = useMainJson();
  const { email } = useAuth();

  useEffect(() => {
    setActiveScreen(configurationTabNames[parentTabIndex][tabIndex]);
    if (!envConf.getOriginName() && !configId && !localStorage.getItem('mainJson')) {
      findCurrentLocation()
        .then((data) => {
          updateOriginSettings({
            latitude: data.latitude,
            longitude: data.longitude,
            radius: origin.DEFAULT_RADIUS,
            height: 0,
            name: originTypes.SpecifyRegion,
          });
        })
        .catch((e) => {
          console.error('Error obtaining location: ', e);
        });
    }
  }, []);

  const handleRegionBasedPropSetting = (val) => {
    if (val.target.value !== originTypes.SpecifyRegion) {
      SIMULATION_ORIGINS.forEach((obj) => {
        if (obj.value == val.target.value) {
          updateOriginSettings({
            latitude: obj.latitude,
            longitude: obj.longitude,
            radius: origin.DEFAULT_RADIUS,
            height: obj.height,
            name: obj.value,
          });
        }
      });
    } else {
      findCurrentLocation()
        .then((data) => {
          updateOriginSettings({
            latitude: data.latitude,
            longitude: data.longitude,
            radius: origin.DEFAULT_RADIUS,
            height: 0,
            name: originTypes.SpecifyRegion,
          });
        })
        .catch((e) => {
          console.error('Error obtaining location: ', e);
        });
    }
  };

  const updateOriginSettings = ({ latitude, longitude, radius, height, name }) => {
    envConf.setOriginLatitude(latitude);
    envConf.setOriginLongitude(longitude);
    envConf.setOriginRadius(radius);
    envConf.setOriginHeight(height);
    envConf.setOriginName(name);

    setEnvConf(EnvironmentModel.getReactStateBasedUpdate(envConf));
    //viewerMaintainer.current = true;
  };

  const setDefaultOriginValues = (val) => {
    updateOriginSettings({
      latitude: 0,
      longitude: 0,
      radius: origin.DEFAULT_RADIUS,
      height: 0,
      name: val.target.value,
    });
  };

  const handleOriginChange = (val) => {
    let keys = Object.keys(val);

    if (val.target.id === 'Latitude') {
      envConf.setOriginLatitude(val.target.value == '' ? 0 : parseFloat(val.target.value));
    } else if (val.target.id === 'Longitude') {
      envConf.setOriginLongitude(val.target.value == '' ? 0 : parseFloat(val.target.value));
    } else if (val.target.id === 'Height') {
      envConf.setOriginHeight(val.target.value == '' ? 0 : parseFloat(val.target.value));
    } else if (val.target.id === 'Radius') {
      envConf.setOriginRadius(val.target.value == '' ? 0 : parseFloat(val.target.value));
    }
    // viewerMaintainer.current = true;
    setEnvConf(EnvironmentModel.getReactStateBasedUpdate(envConf));
  };

  const handleDragStart = (event) => {
    const imgSrc = event.target.src;
    const dragData = {
      type: 'region',
      src: imgSrc,
    };

    event.dataTransfer.setData('text/plain', JSON.stringify(dragData));
  };

  return (
    <Grid container spacing={5} direction='column'>
      {envConf.Origin.name === originTypes.SpecifyRegion && (
        <Grid container item alignItems='center' direction='row' style={{ color: '#F5F5DC' }}>
          <Grid item xs={9.2}>
            <strong>Drag the location icon to set the Simulation Origin</strong>
          </Grid>
          <Grid item xs={0.8}>
            <BootstrapTooltip title='Drag and drop the icon onto the map' placement='top'>
              <img
                src={imageUrls.location}
                alt='Draggable Icon'
                draggable='true'
                onDragStart={(e) => handleDragStart(e)}
                style={{ width: 36, cursor: 'grab', marginRight: 20 }}
              />
            </BootstrapTooltip>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12}>
        <Grid container alignItems='center' direction='row'>
          <Grid item xs={4}>
            <InputLabel
              id='origin-label'
              sx={{ marginRight: 2, width: '200px', flexShrink: 0, color: '#F5F5DC' }}
            >
              Origin
            </InputLabel>
          </Grid>
          <Grid item xs={6}>
            <StyledSelect
              label='Region'
              value={envConf.getOriginName()}
              input={<OutlinedInput />}
              MenuProps={{
                sx: {
                  '& .MuiPaper-root': {
                    backgroundColor: '#F5F5DC',
                  },
                },
              }}
              onChange={handleRegionBasedPropSetting}
              fullWidth
            >
              {SIMULATION_ORIGINS.map((val) => {
                return (
                  <MenuItem value={val.value} key={val.id}>
                    <em>{val.value}</em>
                  </MenuItem>
                );
              })}
            </StyledSelect>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems='center' direction='row'>
          <Grid item xs={4}>
            <InputLabel
              id='latitude-label'
              sx={{ marginRight: 2, flexShrink: 0, color: '#F5F5DC', width: '200px' }}
            >
              Origin Latitude
            </InputLabel>
          </Grid>
          <Grid item xs={6}>
            <TextField
              sx={{
                backgroundColor: '#F5F5DC',
                '& .MuiOutlinedInput-root': {
                  '& .MuiInputBase-input': {
                    padding: '6px 8px',
                  },
                },
              }}
              id='Latitude'
              variant='outlined'
              type='number'
              inputProps={{ step: '.0001' }}
              onChange={handleOriginChange}
              value={envConf.Origin.latitude}
              disabled={envConf.Origin.name === originTypes.SpecifyRegion ? false : true}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems='center' direction='row'>
          <Grid item xs={4}>
            <InputLabel
              id='longitude-label'
              sx={{ marginRight: 2, flexShrink: 0, color: '#F5F5DC', width: '200px' }}
            >
              Origin Longitude
            </InputLabel>
          </Grid>
          <Grid item xs={6}>
            <TextField
              sx={{
                backgroundColor: '#F5F5DC',
                '& .MuiOutlinedInput-root': {
                  '& .MuiInputBase-input': {
                    padding: '6px 8px',
                  },
                },
              }}
              id='Longitude'
              variant='outlined'
              type='number'
              inputProps={{ step: '.0001' }}
              onChange={handleOriginChange}
              value={envConf.Origin.longitude}
              disabled={envConf.Origin.name === originTypes.SpecifyRegion ? false : true}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems='center' direction='row'>
          <Grid item xs={4}>
            <InputLabel
              id='radius-label'
              sx={{ marginRight: 2, flexShrink: 0, color: '#F5F5DC', width: '200px' }}
            >
              Radius (miles)
            </InputLabel>
          </Grid>
          <Grid item xs={6}>
            <TextField
              sx={{
                backgroundColor: '#F5F5DC',
                '& .MuiOutlinedInput-root': {
                  '& .MuiInputBase-input': {
                    padding: '6px 8px',
                  },
                },
              }}
              id='Radius'
              variant='outlined'
              type='number'
              inputProps={{ step: '0.1', min: '0' }}
              onChange={handleOriginChange}
              value={envConf.Origin.radius}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems='center' direction='row'>
          <Grid item xs={4}>
            <InputLabel
              id='time-of-day-label'
              sx={{ marginRight: 2, flexShrink: 0, color: '#F5F5DC', width: '200px' }}
            >
              Time of day
            </InputLabel>
          </Grid>
          <Grid item xs={6}>
            {/* <Tooltip title="Enter time of day (24 Hours Format)" placement='bottom'> */}
            <TimeGridComponent envConf={envConf} setEnvConf={setEnvConf} />
            {/* </Tooltip> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

EnvironmentRegionSetting.propTypes = {
  envConf: PropTypes.object.isRequired,
  setEnvConf: PropTypes.func.isRequired,
  parentTabIndex: PropTypes.number.isRequired,
  tabIndex: PropTypes.number.isRequired,
  configId: PropTypes.string,
};

export default EnvironmentRegionSetting;
