import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Button, TextField } from '@mui/material';

export const StyledButton = styled(Button)`
  border-radius: 10px;
  color: white;
  border: 2px solid #cc7e09;
  background-color: transparent;
  font-size: 1.2rem;
  font-weight: bold;
  word-spacing: 0.2rem;

  &:hover {
    background-color: #cc7e09;
  }

  &:active {
    background-color: #cc7e09;
    border: 2px solid #cc7e09;
  }
`;

export const StyledText = styled.p`
  font-weight: bold;
  font-size: 20px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTextField = styled(TextField)`
  width: 800px;
  height: 200px;
`;

// Modal styles
export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800, // Increase the width
  height: 400, // Increase the height
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const homePageBoxStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  flexDirection: 'column',
  '& > :not(style)': {
    m: 1,
    width: 1000,
  },
};

export const StyledBackground = {
  backgroundImage: 'url("/images/dji-air-4.png")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundAttachment: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100vw',
  height: '100vh',
};

export const StyledBox = {
  background: 'linear-gradient(to bottom, #211401 14%, #3D250199 66%, #3C260350 100%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  textAlign: 'center',
  width: '30vw',
  height: '100vh',
  transform: 'translateX(-93%)',
};

export const StyledHiddenBox = {
  background: 'linear-gradient(to bottom, #211401 24%, #3C260358 100%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  width: '62.9vw',
  height: '20vh',
  position: 'absolute',
  top: '80vh',
  right: '0vw',
  visibility: 'hidden',
  padding: '10px',
  color: 'white',
};

export const StyledAboutUsButton = {
  position: 'absolute',
  bottom: 20,
  right: 20,
  backgroundColor: '#3C260350',
  color: 'white',
  padding: '10px 20px',
  borderRadius: '5px',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#3D250199',
  },
};

export const ConfigsBox = {
  background: 'linear-gradient(to bottom, #211401 14%, #3D250199 66%, #3C260350 100%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  textAlign: 'center',
  width: '70vw',
  height: '100vh',
  p: 6,
  overflowX: 'hidden',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
};

export const ProfilesDivider = {
  width: '40%',
  height: '4px',
  textAlign: 'center',
  backgroundColor: '#DEE2E6',
};
