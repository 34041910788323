import React, { useState } from 'react';
import { Container, TextField, Typography, Link, Snackbar, Alert } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { imageUrls } from '../utils/const';
import { SignInButton } from '../css/muiStyles';
import { callAPI } from '../utils/ApiUtils';
import { SignUpModel } from '../model/AuthModel';
import { useAuth } from '../contexts/authContext';
import { nasaLogo } from '../css/authStyles';

const SignUp = () => {
  const navigate = useNavigate();
  const { authToken } = useAuth();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [user, setUser] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [errors, setErrors] = useState({});

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleChange = (prop) => (event) => {
    setUser((prev) => ({ ...prev, [prop]: event.target.value }));
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.firstname = user.firstname ? '' : 'First name is required';
    tempErrors.lastname = user.lastname ? '' : 'Last name is required';
    tempErrors.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(user.email) ? '' : 'Email is not valid';
    tempErrors.password =
      user.password.length > 6 ? '' : 'Password must be at least 7 characters long';
    tempErrors.confirmPassword =
      user.confirmPassword === user.password ? '' : 'Passwords do not match';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validate()) {
      const signupData = new SignUpModel(user.firstname, user.lastname, user.email, user.password);

      callAPI('api/register', 'POST', signupData.toJSONString(), 'JSON', authToken)
        .then((data) => {
          setSnackbarMessage('Signup successful!');
          setSnackbarSeverity('success');
          setOpenSnackbar(true);
          navigate('/signin');
        })
        .catch((error) => {
          const errorMessage = error.message || 'Signup failed. Please try again!';
          setSnackbarMessage(errorMessage);
          setSnackbarSeverity('error');
          setOpenSnackbar(true);
        });
    }
  };

  return (
    <Container
      component='main'
      maxWidth={false}
      sx={{
        width: '100vw',
        height: '100vh',
        bgcolor: '#ffeee1',
        display: 'flex',
        alignItems: 'center',
        p: '0px !important',
      }}
    >
      <Box
        sx={{
          width: '40vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <img src={'/images/Nasa.png'} style={{ ...nasaLogo }} />
        <Box
          sx={{
            width: '30vw',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            m: 10,
          }}
        >
          <Typography component='h1' variant='h3' sx={{ fontWeight: 'bold', mb: 6 }}>
            Create Account
          </Typography>
          <Box component='form' onSubmit={handleSubmit} sx={{ mt: 1 }}>
            {['firstname', 'lastname', 'email', 'password', 'confirmPassword'].map(
              (field, index) => (
                <TextField
                  key={index}
                  margin='normal'
                  required
                  fullWidth
                  id={field}
                  label={
                    field.charAt(0).toUpperCase() +
                    field
                      .slice(1)
                      .replace(/([A-Z])/g, ' $1')
                      .trim()
                  }
                  name={field}
                  type={field.toLowerCase().includes('password') ? 'password' : 'text'}
                  autoComplete={field}
                  value={user[field]}
                  onChange={handleChange(field)}
                  error={Boolean(errors[field])}
                  helperText={errors[field]}
                  sx={{ bgcolor: '#fffaf7' }}
                />
              ),
            )}
            <SignInButton type='submit' fullWidth variant='contained'>
              Create account
            </SignInButton>
            <Typography variant='body1'>
              Already have an account?
              <Link href='/signin' variant='body1' sx={{ fontWeight: 'bold', ml: 1 }}>
                Login
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
      <img src={imageUrls.auth_drone} width='70%' height='96%' style={{ margin: '1.2rem' }} />

      <Snackbar
        open={openSnackbar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SignUp;
