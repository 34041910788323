export class SignUpModel {
  constructor(firstname, lastname, email, password, group = '') {
    this._firstname = firstname;
    this._lastname = lastname;
    this._email = email;
    this._password = password;
    this._group = group;
  }

  toJSONString() {
    return {
      first_name: this._firstname,
      last_name: this._lastname,
      email: this._email,
      password: this._password,
      group: this._group,
    };
  }
}

export class SignInModel {
  constructor(email, password) {
    this._email = email;
    this._password = password;
  }

  toJSONString() {
    return {
      username: this._email,
      password: this._password,
    };
  }
}
