// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{ 
    box-sizing: border-box; 
} 

body{ 
    margin: 0;
    font-family: 'Arial';
} 

.nav{ 

    background-color: #1c34d4; 
    color: rgb(245, 245, 245); 
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    gap: 2rem; 
    padding: 0 1rem; 
}  

.site-title{ 
    font-size: 4rem;
} 

.nav ul {  
    padding: 0; 
    margin: 0; 
    list-style: none; 
    display: flex; 
    gap: 1rem;
} 

.nav a{ 
    color: inherit;  
    text-decoration: none; 
} 
.nav li.active{ 
    background-color: #e7e7e7
} 

.nav li:hover{ 
    background-color: #2929d4
}   

.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; 
}

.button-container {
    margin-top: 20px; 
}

html {
    font-size: 16px;
  }
  
  @media (max-width: 1200px) {
    html {
      font-size: 15px;
    }
  }
  
  @media (max-width: 992px) {
    html {
      font-size: 14px;
    }
  }
  
  @media (max-width: 768px) {
    html {
      font-size: 13px;
    }
  }
  
  @media (max-width: 576px) {
    html {
      font-size: 12px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/css/styles.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,SAAS;IACT,oBAAoB;AACxB;;AAEA;;IAEI,yBAAyB;IACzB,yBAAyB;IACzB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,SAAS;IACT,gBAAgB;IAChB,aAAa;IACb,SAAS;AACb;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;AACA;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;EACjB;;EAEA;IACE;MACE,eAAe;IACjB;EACF;;EAEA;IACE;MACE,eAAe;IACjB;EACF;;EAEA;IACE;MACE,eAAe;IACjB;EACF;;EAEA;IACE;MACE,eAAe;IACjB;EACF","sourcesContent":["*{ \n    box-sizing: border-box; \n} \n\nbody{ \n    margin: 0;\n    font-family: 'Arial';\n} \n\n.nav{ \n\n    background-color: #1c34d4; \n    color: rgb(245, 245, 245); \n    display: flex; \n    justify-content: space-between; \n    align-items: center; \n    gap: 2rem; \n    padding: 0 1rem; \n}  \n\n.site-title{ \n    font-size: 4rem;\n} \n\n.nav ul {  \n    padding: 0; \n    margin: 0; \n    list-style: none; \n    display: flex; \n    gap: 1rem;\n} \n\n.nav a{ \n    color: inherit;  \n    text-decoration: none; \n} \n.nav li.active{ \n    background-color: #e7e7e7\n} \n\n.nav li:hover{ \n    background-color: #2929d4\n}   \n\n.main-content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh; \n}\n\n.button-container {\n    margin-top: 20px; \n}\n\nhtml {\n    font-size: 16px;\n  }\n  \n  @media (max-width: 1200px) {\n    html {\n      font-size: 15px;\n    }\n  }\n  \n  @media (max-width: 992px) {\n    html {\n      font-size: 14px;\n    }\n  }\n  \n  @media (max-width: 768px) {\n    html {\n      font-size: 13px;\n    }\n  }\n  \n  @media (max-width: 576px) {\n    html {\n      font-size: 12px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
