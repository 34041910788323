export class Point {
  constructor(latitude, longitude) {
    this._latitude = latitude;
    this._longitude = longitude;
  }

  get latitude() {
    return this._latitude;
  }

  get longitude() {
    return this._longitude;
  }

  set latitude(value) {
    this._latitude = value;
  }

  set longitude(value) {
    this._longitude = value;
  }

  toJSONString() {
    return {
      latitude: this._latitude,
      longitude: this._longitude,
    };
  }
}
