

export class MonitorModel {

    constructor(){
    }

    toJSONString(){
        return {};
    }

}