export const numberStyle = {
  fontSize: '1rem',
  fontWeight: 'bold',
  color: '#4fc3f7',
};

export const labelStyle = {
  color: 'white',
  alignItems: 'center',
  fontSize: '1.2rem',
  marginRight: '.4rem',
};

export const iconStyle = {
  color: 'white',
  fontSize: '1.8rem',
};
